<template>
    <div>
        <center>
            <div class="search-bar container-pa16">
                <img :src="require('@/assets/imgs/svg/0527-l.svg')" />
                <input
                    type="text"
                    placeholder="搜索附近的旅電"
                    v-model="searchInput"
                    @keyup.enter="$_searchStore()"
                />
            </div>
        </center>
        <div
            v-if="stores.length > 0"
            class="scroll-container container-pa16"
            @scroll="$_onScroll"
        >
            <div v-for="item in stores" :key="item.id">
                <router-link :to="`/store/${item.id}`" class="store">
                    <div
                        class="main-img"
                        :style="{
                            'background-image': `url(${item.thumbnail})`,
                        }"
                    ></div>
                    <div class="store-inform">
                        <h3 class="title">{{ item.name }}</h3>
                        <div class="flex-inner-columns">
                            <div class="column-5 text-75">
                                <p>營業時間</p>
                                <span>{{
                                    getStoreTime(item.startTime, item.endTime)
                                }}</span>
                                <div class="flex-container margin-top-pa4">
                                    <img
                                        class="front-img-w12"
                                        :src="
                                            require('@/assets/imgs/svg/0603-s.svg')
                                        "
                                    />
                                    <span class="text-1000-graylight bold"
                                        >{{ getDisIntoKM(item.dis) }}KM</span
                                    >
                                </div>
                            </div>
                            <div class="column-4">
                                <div class="flex-container flex-center">
                                    <img
                                        class="front-img-w12"
                                        :src="
                                            require('@/assets/imgs/svg/0603-b.svg')
                                        "
                                    />
                                    <span class="text-75"
                                        >可借：{{ item.forBorrow }}</span
                                    >
                                </div>
                                <div
                                    class="flex-container margin-top-pa4 flex-center"
                                >
                                    <img
                                        class="front-img-w12"
                                        :src="
                                            require('@/assets/imgs/svg/0603-b.svg')
                                        "
                                    />
                                    <span class="text-75"
                                        >可還：{{ item.forGiveBack }}</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
                <hr class="gray-hr" />
            </div>
            <div class="loading-container">
                <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div class="bottom-name">
                <img :src="require('@/assets/imgs/svg/0527-c.svg')" />
            </div>
        </div>
    </div>
</template>
<script>
import { handleErr } from "@/helpers";
export default {
    created() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.setGeolocation);
        }
    },
    mounted() {
        const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
    },
    watch: {
        map_center: {
            deep: true,
            immediate: true,
            handler() {
                this.setStore();
            },
        },
    },
    data() {
        return {
            isLoading: true,
            map_center: {},
            currentPage: 1,
            searchInput: "",
            stores: [
                // {
                //     name: "店家名稱",
                //     thumbnail: "test.jpg",
                //     startTime: "10:00",
                //     endTime: "21:00",
                //     dis: 1223,
                //     forBorrow: 2,
                //     forGiveBack: 4,
                //     position: {
                //         lat: 25.043632,
                //         lng: 121.520121,
                //     },
                //     link: "",
                // },
            ],
        };
    },
    methods: {
        $_onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
            if (this.isLoading) return;
            if (scrollTop + clientHeight >= scrollHeight) {
                this.currentPage += 1;
                this.setStore();
            }
        },
        setGeolocation(position) {
            this.map_center = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };
        },
        $_searchStore() {
            this.currentPage = 1;
            this.setStore();
        },
        async setStore() {
            if (isNaN(this.map_center.lat)) return;
            this.isLoading = true;
            try {
                const res = await this.$axios.post(
                    "/customer/store/queryStores",
                    {
                        latitude: this.map_center.lat,
                        longitude: this.map_center.lng,
                        name: this.searchInput || "",
                        page: this.currentPage || 1,
                    }
                );
                const new_stores = res.data.result.records.map((record) => ({
                    id: record.id,
                    name: record.name,
                    thumbnail: record.img
                        ? `https://s3lvelect.s3.ap-east-1.amazonaws.com/${record.img}`
                        : "",
                    startTime: record.startTime,
                    endTime: record.endTime,
                    dis: record.dis,
                    forBorrow: record.unUse,
                    forGiveBack: record.useNum,
                    position: {
                        lat: record.latitude,
                        lng: record.longitude,
                    },
                    link: record.link,
                }));
                if (this.currentPage === 1) {
                    this.stores = new_stores;
                } else {
                    // this.stores = this.stores.concat(new_stores);
                    this.stores.push(...new_stores);
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
            this.isLoading = false;
        },
        getStoreTime(startTime, endTime) {
            return `${startTime}~${endTime}`;
        },
        getDisIntoKM(m) {
            if (isNaN(m)) return "";
            return (m / 1000).toFixed(2);
        },
    },
};
</script>
